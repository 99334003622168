//判断是否闰年
const runNian = (_year) => {
  if (_year % 400 === 0 || (_year % 4 === 0 && _year % 100 !== 0)) {
    return true;
  } else {
    return false;
  }
};
//获取月份天数
const mGetDate = (year, month) => {
  let d = new Date(year, month, 0);
  return d.getDate();
};
//判断某年某月的1号是星期几
const getFirstDay = (_year, _month) => {
  var allDay = 0,
    y = _year - 1,
    i = 1;
  allDay =
    y + Math.floor(y / 4) - Math.floor(y / 100) + Math.floor(y / 400) + 1;
  for (; i < _month; i++) {
    switch (i) {
      case 1:
        allDay += 31;
        break;
      case 2:
        if (runNian(_year)) {
          allDay += 29;
        } else {
          allDay += 28;
        }
        break;
      case 3:
        allDay += 31;
        break;
      case 4:
        allDay += 30;
        break;
      case 5:
        allDay += 31;
        break;
      case 6:
        allDay += 30;
        break;
      case 7:
        allDay += 31;
        break;
      case 8:
        allDay += 31;
        break;
      case 9:
        allDay += 30;
        break;
      case 10:
        allDay += 31;
        break;
      case 11:
        allDay += 30;
        break;
      case 12:
        allDay += 31;
        break;
    }
  }
  return allDay % 7;
};

export const getDateList = (registerDayList) => {
  const date = new Date();
  const dateList = [];
  // 当前年份
  const currentYear = date.getFullYear();
  // 当前月份
  const currentMonth = date.getMonth() + 1;
  // 当前天数
  const currentDate = date.getDate();
  //当前月份一共有几天
  const days = mGetDate(currentYear, currentMonth);
  //当前月上一月份一共有几天
  const lastDays =
    currentMonth - 1 > 0
      ? mGetDate(currentYear, currentMonth - 1)
      : mGetDate(currentYear - 1, 12);
  //1号是周几
  const dayWeek = getFirstDay(currentYear, currentMonth);
  //创建初始日历
  for (let i = 0; i < days; i++) {
    const dateListItem = {
      day: i + 1,
      year: currentYear,
      month: currentMonth,
      bgColor: false,
      isRmb: false,
    };
    //判断是否是人名币
    registerDayList.forEach((item) => {
      if (item === dateListItem.day) {
        dateListItem.isRmb = true;
      }
    });
    // 判断是否变色
    if (dateListItem.day < currentDate) {
      dateListItem.bgColor = true;
    }
    dateList.push(dateListItem);
  }

  // 头部需插入上月天数等于dayWeek。一号为周日是0
  for (let i = lastDays; i > lastDays - dayWeek; i--) {
    const dateListItem = {
      day: i,
      year: currentMonth - 1 > 0 ? currentYear : currentYear - 1,
      month: currentMonth - 1 > 0 ? currentMonth - 1 : 12,
      bgColor: true,
      isRmb: false,
    };
    dateList.unshift(dateListItem);
  }
  //尾部需要插入下个月天数等于42-当前dateList的长度
  const nextDays = 42 - dateList.length;
  for (let i = 0; i < nextDays; i++) {
    const dateListItem = {
      day: i + 1,
      year: currentMonth + 1 > 12 ? currentYear + 1 : currentYear,
      month: currentMonth + 1 > 12 ? 1 : currentMonth + 1,
      bgColor: true,
      isRmb: false,
    };
    dateList.push(dateListItem);
  }
  //   console.log(42 - dateList.length);
  console.log(dateList);

  return dateList;
};
