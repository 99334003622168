<template>
  <div class="center-home-page">
    <el-carousel height="200px">
      <el-carousel-item v-for="(item, index) in banners" :key="index">
        <img :src="item" @click="navigate('/center/recommend')" />
      </el-carousel-item>
    </el-carousel>
    <div class="center-user">
      <el-row type="flex" :gutter="25" align="middle">
        <el-col :span="4" style="width: 95px">
          <div class="user-logo">
            <img :src="account.avatarUrl" alt="头像" />
          </div>
        </el-col>
        <el-col :span="4" style="width: 95px">
          <div class="user-name">
            {{ userInfo.name || account.userName }}
          </div>
          <span class="user-member">{{ account.level }}</span>
        </el-col>
        <el-col :span="4" style="width: 150px">
          <div class="user-recommond">
            {{ $t("my_re_code") }}
            <el-tooltip placement="right" effect="light">
              <div slot="content">
                <img
                  style="width: 150px; height: 150px"
                  :src="
                    'https://www.sudiaoba.com/inviteImgDown.aspx?uid=' +
                      account.id || '' + ''
                  "
                  alt=""
                />
              </div>
              <i class="el-icon-aliico" style="font-size: 24px"></i>
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="12"></el-col>
        <el-col :span="4" style="width: 140px">
          <el-button
            round
            style="width: 140px"
            :type="isComplete ? 'info' : 'primary'"
            @click="checkin()"
            ><i class="el-icon-ali6" size="20"></i>{{ checkInText }}</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="10" class="user-specs">
        <el-col :span="8">
          <div class="user-spec">
            <div class="spec-title">
              <div class="l">
                {{ $t("my_gold") }}
              </div>
              <div class="r" @click="navigate('/center/gold-record')">
                {{ $t("view_detail") }}>
              </div>
            </div>
            <div class="spec-content">
              <span class="num">{{ account.balance }}</span>
              <span class="num-btn"> {{ $t("detail_rule") }}</span>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="user-spec">
            <div class="spec-title">
              <div class="l">
                {{ $t("my_credit") }}
              </div>
              <div class="r" @click="navigate('/center/credit')">
                {{ $t("view_detail") }}>
              </div>
            </div>
            <div class="spec-content">
              <span class="num">{{ account.credit }}</span>
              <el-tag effect="dark" size="mini"
                >{{ $t("income") }} {{ rateOfIncome }}</el-tag
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="center-tasks">
      <div class="center-tasks__header">
        <el-row type="flex" justify="between" align="middle" height="32px">
          <el-col :span="12" class="center-tasks__header-title">{{
            $t("task_list")
          }}</el-col>
          <el-col :span="10"></el-col>
          <el-col :span="2" width="100px">
            <el-button
              size="small"
              type="primary"
              round
              plain
              @click="navigate('/tasks')"
              >{{ $t("view_more") }} >
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="center-tasks__content" v-loading="loading">
        <el-row :gutter="20">
          <el-col :span="8" v-for="i in tasks" :key="i.id">
            <div class="task">
              <div class="task-title">
                <div>{{ i.number }}</div>
                <div>{{ i.title }}</div>
              </div>
              <div class="task-price">
                <span>{{ i.minPoints }}-{{ i.maxPoints }}</span
                ><span>{{ $t("gold") }}</span>
              </div>
              <div class="task-apply">
                <div class="task-apply__line">
                  <span class="label">{{ $t("use_terminal") }}：</span>
                  <span class="context">{{ i.platform }}</span>
                </div>
                <div class="task-apply__line">
                  <span class="label">{{ $t("time_range") }}：</span>
                  <span class="context"
                    >{{ $t("about") }}{{ i.loi }}{{ $t("minute") }}</span
                  >
                </div>
                <div class="task-apply__line">
                  <span class="label">{{ $t("hard_level") }}：</span>
                  <span class="context">{{ i.ir }}%</span>
                </div>
              </div>
              <div class="task-buttons">
                <el-button
                  size="small"
                  type="primary"
                  plain
                  round
                  @click="takeIn(i)"
                  >{{
                    i.loop
                      ? $t("immediately_take_in_mutli")
                      : $t("immediately_take_in")
                  }}</el-button
                >
                <el-button
                  v-if="i.allowedHide"
                  size="small"
                  plain
                  round
                  @click="hiddenTask(i)"
                  >{{ $t("hidden") }}</el-button
                >
                <el-button
                  size="small"
                  plain
                  round
                  v-if="i.allowedShare"
                  @click="setDialogVisible({ shareSurvey: i.encryptId })"
                  >{{ $t("share") }}</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 答题签到 -->
      <AnswerRegister
        :answerRegisterStatus="answerRegisterStatus"
        @answerSubmit="answerSubmit"
        @registerClose="registerClose"
        @changeAnswerIndex="changeAnswerIndex"
      ></AnswerRegister>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<style lang="scss">
.center-home-page {
  .center-tasks {
    &__content {
      .el-loading-mask {
        margin-top: 20px;
        border-radius: 10px;
      }
    }
  }
}
</style>

<script>
import * as banner01 from "assets/images/banner01.png";
import { mapActions, mapGetters } from "vuex";
import { filter } from "lodash-es";
import AnswerRegister from "components/answer-register";
import { getDateList } from "utlis/calendar.js";
export default {
  components: { AnswerRegister },
  data() {
    return {
      banners: [banner01],
      isComplete: false,
      tasks: [],
      loading: false,
      takeInLoading: false,
      answerRegisterStatus: {
        answerDialogVisible: false,
        dateList: [],
        answerIndex: "",
        registerDialogVisible: false,
        question: {},
      },
    };
  },
  async created() {
    this.loading = true;
    const res1 = await this.$http.get(
      `/api/v1/Accounts/${this.account.id}/SurveysOfLucid`
    );
    console.log('res1',res1)

    const res2 = await this.$http.get(
      `/api/v1/Accounts/${this.account.id}/SurveysOfSSI`
    );

    const res3 = await this.$http.get(
      `/api/v1/Accounts/${this.account.id}/IndividualSurveys`
    );

    this.tasks = [
      ...(res1.data || []).map((v) => ({
        ...v,
        isNotIndividual: true,
      })),
      ...(res2.data || []).map((v) => ({
        ...v,
        isNotIndividual: true,
      })),
      ...(res3.data || []),
    ];
    console.log('this.tasks',this.tasks)
    this.loading = false;
  },
  mounted() {
    this.getTodayComplate();
    setTimeout(() => {
      this.getSigninQuestion();
    }, 1000);
    this.getSigninQuestion();
  },
  methods: {
    ...mapActions(["setDialogVisible"]),
    //点击签到
    async checkin() {
      //签到修改
      //签到修改
      if (this.isComplete || this.takeInLoading) {
        //用户签到过后直接显示日历签到
        this.answerRegisterStatus.registerDialogVisible = true;
        return;
      }
      // 判断是否存在问题,空对象直接显示日历
      if (JSON.stringify(this.answerRegisterStatus.question) == "{}") {
        this.takeInLoading = true;
        const {
          data: { isComplete },
        } = await this.$http.post(`/api/v1/Accounts/${this.account.id}/Today`);
        if (isComplete) {
          this.$notify({
            title: this.$t("checkin_success"),
            type: "success",
          });
          this.isComplete = true;
          this.getTodayComplate();
          this.answerRegisterStatus.registerDialogVisible = true;
        }
        this.takeInLoading = false;
      } else {
        this.answerRegisterStatus.answerDialogVisible = true;
      }
      //
    },
    //选中问题
    changeAnswerIndex(index) {
      this.answerRegisterStatus.answerIndex = index;
    },
    //点击问题提交
    async answerSubmit() {
      this.answerRegisterStatus.registerDialogVisible = true;
      this.takeInLoading = true;
      const {
        data: { isComplete },
      } = await this.$http.post(`/api/v1/Accounts/${this.account.id}/Today`);
      if (isComplete) {
        this.$notify({
          title: this.$t("checkin_success"),
          type: "success",
        });
        this.isComplete = true;
        this.getTodayComplate();
      }
      this.takeInLoading = false;
    },
    registerClose() {
      this.answerRegisterStatus.registerDialogVisible = false;
      this.answerRegisterStatus.answerDialogVisible = false;
    },
    //签到前的问题
    getSigninQuestion() {
      this.$http
        .get(`/api/v1/SigninQuestion`, {
          params: {
            id: this.account.id,
          },
        })
        .then((v) => {
          if (JSON.stringify(v.data) == "{}") {
            this.answerRegisterStatus.question = {};
          } else {
            this.answerRegisterStatus.question = v.data.question;
          }
        });
    },
    takeIn(t) {
      if (t.isNotIndividual) {
        return window.open(t.surveyLink);
      }
      return this.$router.push(`/pages/survey/${t.encryptId}`);
    },
    hiddenTask(survery) {
      this.$confirm(this.$t("task_hidden_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("tip"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(`/api/v1/Accounts/${this.account.id}/HiddenSurvey`, null, {
              params: {
                surveyID: survery.encryptId,
                surveySeed: survery.surveySeed,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                this.$message.success(this.$t("hidden_success"));
                this.tasks = filter(this.tasks, (t) => t.id !== survery.id);
              } else {
                this.$message.error(res.statusText);
              }
            })
            .catch(() => {
              this.$message.error(this.$t("hidden_fail"));
            });
        })
        .catch(() => {});
    },
    getTodayComplate() {
      this.$http
        .get(`/api/v1/Accounts/${this.account.id}/TodayStatus`)
        .then((v) => {
          this.isComplete = v.data.isComplete;
          this.answerRegisterStatus.dateList = getDateList(v.data.days);
        });
    },
    navigate(to) {
      if (
        to === this.$route.name ||
        (["/", "center", "home"].includes(to) &&
          ["/", "center", "home"].includes(this.$route.name))
      )
        return;
      this.$router.push(
        to,
        () => {},
        () => {}
      );
    },
  },
  computed: {
    ...mapGetters(["account", "userInfo"]),
    accountLevel() {
      return [this.$t("regular_member")][[1].indexOf(this.account.level) || 0];
    },
    checkInText() {
      return this.isComplete ? this.$t("sign_did") : this.$t("sign_imme");
    },
    rateOfIncome() {
      if (this.account.credit < 100) {
        return "1 " + this.$t("times");
      } else if (this.account.credit >= 100 && this.account.credit < 200) {
        return "1.05 " + this.$t("times");
      } else if (this.account.credit >= 200 && this.account.credit < 500) {
        return "1.1 " + this.$t("times");
      } else if (this.account.credit >= 500 && this.account.credit < 800) {
        return "1.15 " + this.$t("times");
      } else if (this.account.credit >= 800 && this.account.credit <= 1000) {
        return "1.2 " + this.$t("times");
      } else {
        return "1 " + this.$t("times");
      }
    },
  },
};
</script>
